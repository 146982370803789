import React from "react"
import {graphql} from "gatsby"
import shuffle from "shuffle-array"
import MainLayout from "Layout/layout.jsx"
import Footer from "Layout//Footer/Footer.jsx"
import FlexibleContent from "Layout/FlexibleContent/FlexibleContent.jsx"
import PageTransition from "Layout/PageTransition/PageTransition.jsx"
import {dynamicContentShuffle} from "../helper/dynamicContentShuffle"
import {metaData} from "../helper/metaData"

const Project = ({data, location, pageContext}) => {
  const {page} = data
  let {relatedProjects, relatedStories} = data

  const dynamicContent = dynamicContentShuffle(relatedProjects, relatedStories)

  relatedProjects = shuffle(relatedProjects.edges)
  relatedStories = shuffle(relatedStories.nodes).slice(0, 3)

  return (
    <MainLayout
      location={location}
      metaData={metaData(page)}
      pageContext={pageContext}
      translations={page.translations}
    >
      <PageTransition>
        {
          page.flex.content.length > 0 && (
            <FlexibleContent
              content={page.flex.content}
              dynamicContent={dynamicContent}
              location={location}
              pageName="index"
              relatedProjects={relatedProjects}
              relatedStories={relatedStories}
              slug={pageContext.slug}
            />
          )
        }
      </PageTransition>
      {page.footer && (<Footer teasers={page.footer.footerTeasers} headline={page.footer.headline} location={location} lang={pageContext.lang} translations={page.translations} />)}
    </MainLayout>
  )
}

export const projectQuery = graphql`query projectPageQuery($id: String!, $lang: String!) {
  page: wpContentNode(id: {eq: $id}) {
    ... on WpProject {
      title
      databaseId
      flex {
        content {
          __typename
          ...ProjectHeroSectionFragment
          ...ProjectHeroSectionSliderFragment
          ...ProjectServicesTeaserSectionFragment
          ...ProjectBasicSliderSectionFragment
          ...ProjectTeaserSectionFragment
          ...ProjectProgressSliderSectionFragement
          ...ProjectRelatedProjectsSectionFragment
          ...ProjectRelatedStorySectionFragment
          ...ProjectTextItemsColumnsSectionFragment
          ...ProjectJobOverviewSectionFragment
          ...ProjectJobTeaserSectionFragment
          ...ProjectContactPersonsSectionFragment
          ...ProjectLocationSectionFragment
          ...ProjectInquirySectionFragment
          ...ProjectHeadlineSectionFragment
          ...ProjectTextSectionFragment
          ...ProjectTextAndImagesColumnSectionFragment
          ...ProjectProjectOverviewSectionFragment
          ...ProjectCompetenceSectionFragment
          ...ProjectTeamOverviewSectionFragment
          ...ProjectTimelineSliderSectionFragement
          ...ProjectNewsOverviewSectionFragment
          ...ProjectCurrentNewsTeaserSectionFragment
          ...ProjectFeaturedContentSectionFragment
          ...ProjectDynamicContentSectionFragment
          ...ProjectJobApplicationSectionFragment
          ...ProjectEmbedSectionFragment
        }
      }
      translations {
        uri
        language {
          slug
        }
      }
      ...ProjectFooterFragment
    }
  }
  relatedProjects: allWpProject(filter: {id: {ne: $id}, language: {slug: {eq: $lang}}, title: {ne: "DO NOT DELETE"}}) {
    edges {
      node {
        title
        slug
        uri
        date
        databaseId
        nodeType
        postImages {
          postImages {
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 862, quality: 80, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
  relatedStories: allWpPost(filter: {id: {ne: $id}, language: {slug: {eq: $lang}}, title: {ne: "DO NOT DELETE"}}) {
    nodes {
      title
      slug
      uri
      date
      databaseId
      nodeType
      featuredImage {
        node {
          sourceUrl
          mediaDetails {
            height
            width
          }
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`

export default Project
